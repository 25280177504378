@import url('https://fonts.googleapis.com/earlyaccess/nanumgothic.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

:root {
  --color-background: #f7f2ed;
  --color-background-playlist: #f2e7da;
  --color-button: #f2e7da;
  --color-signup-btn: #111032;
  --color-menu: #414140;
  --color-menu-remove-icon: #fc7070;
  --color-tag-background: #ffbd6f;
  --color-tag-clicked: rgba(81, 163, 30, 0.5);
  font-size: 62.5%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nanum Gothic', 'serif';
  /* font-family: 'Poppins', sans-serif; */
}

body {
  background: #f7f2ed;
}

ul {
  list-style: none;
}

a {
  color: #000 !important;
  text-decoration: none !important;
}

button {
  cursor: pointer;
}

input {
  border: none;
  outline: none;
}
